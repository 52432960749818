import React, { useEffect, useMemo, useState } from 'react';
import polygonsIntersect from 'polygons-intersect';
import data from './piedmont.json';
import { GeolocatedProps, geolocated } from 'react-geolocated';
import * as d3 from 'd3';
import { headingDistanceTo } from 'geolocation-utils';

const LAT_TO_METERS = 111111;


function vlen(vector: number[]) {
  return Math.sqrt(vector[0] * vector[0] + vector[1] * vector[1])
}

function vnegate(v: number[]) {
  return [-v[0], -v[1]]
}

function vadd(v1: any[], v2: any[]) {
  return [v1[0] + v2[0], v1[1] + v2[1]]
}

function vsub(v1: number[], v2: number[]) {
  return [v1[0] - v2[0], v1[1] - v2[1]]
}

function vscale(vector: any[], factor: number) {
  return [vector[0] * factor, vector[1] * factor]
}

function vnorm(v: any[]) {
  return [-v[1], v[0]]
}
function closestPointOnPolygon(point: any[], poly: any[]) {
  let shortestDist = Number.MAX_VALUE
  let closestPointOnPoly = poly[0]

  poly.forEach(function (p1: any[], i: number) {
      var prev = (i === 0 ? poly.length : i) - 1,
          p2 = poly[prev],
          line = vsub(p2, p1)

      if (vlen(line) === 0) return vlen(vsub(point, p1))

      var norm = vnorm(line),
          x1 = point[0],
          x2 = norm[0],
          x3 = p1[0],
          x4 = line[0],
          y1 = point[1],
          y2 = norm[1],
          y3 = p1[1],
          y4 = line[1],
          j =
              (x3 - x1 - (x2 * y3) / y2 + (x2 * y1) / y2) /
              ((x2 * y4) / y2 - x4)

      let currentDistanceToPoly
      let currentPointToPoly
      if (j < 0 || j > 1) {
          const a = vsub(point, p1)
          const aLen = vlen(a)
          const b = vsub(point, p2)
          const bLen = vlen(b)
          if (a < b) {
              currentPointToPoly = vnegate(a)
              currentDistanceToPoly = aLen
          } else {
              currentPointToPoly = vnegate(b)
              currentDistanceToPoly = bLen
          }
      } else {
          const i = (y3 + j * y4 - y1) / y2

          currentPointToPoly = vscale(norm, i)
          currentDistanceToPoly = vlen(currentPointToPoly)
      }

      if (currentDistanceToPoly < shortestDist) {
          closestPointOnPoly = vadd(point, currentPointToPoly)
          shortestDist = currentDistanceToPoly
      }
  })

  return [closestPointOnPoly, shortestDist]
}

// const getDistance = (x: any,y: any) => {
//   const poly = {
//     "type": "FeatureCollection",
//     "features": [
//       {
//         "type": "Feature",
//         "geometry": {
//           "type": "Polygon",
//           "coordinates": [
//             data.map((el) => ([el.x, el.y]))
//           ]
//         }
//       }
//     ]
//   }

//   const point = {"type": "Point", "coordinates": [x, y]}
//   const distance = d3.geoDistance(poly, point);
// }



const PiedmontComponent = (props: GeolocatedProps) => {

  const { latitude: lat, longitude: lon, accuracy, heading } = props.coords || {};
  const { isGeolocationEnabled, isGeolocationAvailable } = props;


  const [inPiedmont, setInPiedmont] = useState<boolean | undefined>(undefined);
  const [distance, setDistance] = useState<number | undefined>(undefined);
  const [directionToPiedmont, setDirection] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!lat || !lon || !accuracy) {
      return;
    }
    const circumference = 6371000 * Math.PI * 2;

    const angle = accuracy / circumference * 360;

    const circle = d3.geoCircle().center([lon, lat]).radius(angle);

    const circleCoords = circle().coordinates[0].map(([x, y]) => ({ x, y }))

    const intersecting = polygonsIntersect(data, circleCoords)
    setInPiedmont(!!intersecting.length);

    if (!intersecting.length) {
      const d = data.map((el) => ([el.x, el.y]));
      const [closestPointOnPoly, shortestDist] = closestPointOnPolygon([lon, lat], d);
      // setDistance(Math.round(shortestDist*LAT_TO_METERS));
      const heading = headingDistanceTo({lat, lon}, { lat: closestPointOnPoly[1], lon: closestPointOnPoly[0] })
      setDistance(Math.round(heading.distance));
      setDirection(heading.heading);
    }
  }, [lat, lon, accuracy]);

  const headingToPiedmont = useMemo(() => directionToPiedmont && heading ? Math.round(directionToPiedmont-heading) : 0, [directionToPiedmont, heading])
  const alpha = useDeviceOrientation();
  console.log(alpha)
  if (!isGeolocationEnabled || !isGeolocationAvailable) {
    return (<div>Please enabled geolocation.</div>)
  }

  const searchParams = new URLSearchParams(window.location.search)
  const debug = searchParams.get('debug');

  return (<div>
    {
      inPiedmont === undefined && "Loading..."
    }
    { inPiedmont === true && "You are in Piedmont!" }
    { inPiedmont === false && `You are not in Piedmont. ${distance && `You are approx. ${distance} meters away.`}` }
    {/* {directionToPiedmont && <Compass heading={directionToPiedmont} />} */}
    { directionToPiedmont && <div>Heading to Piedmont: {headingToPiedmont} degrees</div>}
    { debug && <div>
      <pre>Heading To Piedmont {headingToPiedmont} </pre>
      <pre>Direction to Piedmont {directionToPiedmont}</pre>
      <pre>Device Heading {heading} </pre>
      <pre>Device Alpha {alpha}</pre>
    </div> }
  </div>)
}

const isChrome = () => {
  const brands = navigator.userAgent.match(/(Chrome|Chromium)/);
  return brands && brands.length > 0;
}

const useDeviceOrientation = () => {
  const [alpha, setAlpha] = useState<number | undefined>(undefined);
  let _alpha = 0;
  if(window.DeviceOrientationEvent) {
    window.addEventListener('deviceorientation', function(event: DeviceOrientationEvent & { webkitCompassHeading?: number }) {
      // Check for iOS property
      if(event.webkitCompassHeading) {
        _alpha = event.webkitCompassHeading;
      }
      // non iOS
      else {
        if (event.alpha) {
          _alpha = event.alpha;
        }
        
        if(!isChrome()) {
          // Assume Android stock
          if (alpha) {
            _alpha = alpha-270;
          }
          
        }
      }
      setAlpha(_alpha);
  }
  )}
  return alpha;
}

// const Compass = ({ heading }: { heading: number }) => {
//   const svgRef = React.useRef<SVGSVGElement>(null);
//   // if (svgRef.current) {
//     const svg = d3.select(svgRef.current);
//     svg.selectAll('*').remove();
//     svg.append('line').style("stroke", "black").attr('x1', 50).attr('y1', 50).attr('x2', 50).attr('y2', 0);
//     // d3.select(svg.current).selectAll('*').remove();
    
//   // }
//   return (<div><span>Compass</span>{svgRef.current}</div>)

// }


const Piedmont = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  watchPosition: true,
  userDecisionTimeout: 5000,
})(PiedmontComponent);


export default Piedmont;